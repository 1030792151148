import { FC, useEffect, useRef } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import { useGlobal } from '../../context/global.context';
import { Header, Image, Layout, ParrallaxTiles } from '../../components';
import { Footer } from '../../components/Footer';
import { SubscribeEmail } from '../../components/Footer/subscribeEmail';
import { useTranslation } from 'react-i18next';

export const Home: FC = () => {
  const { t } = useTranslation();
  const containerRef = useRef<any>(null);
  const { mobileMenu, mobileMenuClose } = useGlobal();

  // const handleTitleAnimate = () => {
  //   const sectionTitles = document.querySelectorAll('.section-title');

  //   sectionTitles.forEach((item: any) => {
  //     const h2Title = item.querySelector('h2');
  //     const spanTitle = item.querySelector('span');

  //     if (!spanTitle) {
  //       const createSpanClone = document.createElement('span');
  //       createSpanClone.innerHTML = h2Title.innerHTML;
  //       createSpanClone.classList.add('title-line');
  //       h2Title.appendChild(createSpanClone);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   setTimeout(() => handleTitleAnimate(), 1000);
  // }, []);

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: containerRef.current,
      smooth: true,
      getSpeed: true,
      smoothMobile: 0.8,
      useKeyboard: false,
      mobile: {
        smooth: false,
      },
    });

    scroll.on('scroll', (o: any) => {
      const windowInnerHeight = window.innerHeight;
      const scrollY = o.scroll.y;

      if (mobileMenu) {
        mobileMenuClose();
      }

      Object.values(o.currentElements).forEach((element: any, i: number) => {
        const { top } = element;
        const currentEl = Object.keys(o.currentElements)[i];

        if (top - windowInnerHeight / 2 < scrollY) {
          o.currentElements[currentEl].el.classList.add('is-inview-active');
        } else {
          o.currentElements[currentEl].el.classList.remove('is-inview-active');
        }

        const sectionHeight = o.currentElements[currentEl].el.offsetHeight;
        const a = scrollY + windowInnerHeight - top - sectionHeight;
        const w = Math.round(
          (8 * a) / ((windowInnerHeight + sectionHeight) / 100),
        );
        const g = Math.min(100, Math.max(0, w));
        const sectionMask = o.currentElements[currentEl].el.querySelector(
          '.section-title h2 span.title-line',
        );

        const sectionMaskMobile = o.currentElements[
          currentEl
        ].el.querySelectorAll('.section-title h2 span.line');

        if (sectionMaskMobile.length > 0) {
          sectionMaskMobile.forEach((item: any) => {
            item.style.width = `${g}%`;
          });
        }

        if (sectionMask) {
          sectionMask.style.width = `${g}%`;
        }
      });

      const findAnimateClass = Object.values(o.currentElements).find(
        (t: any) => {
          return 'toline' === t.class;
        },
      );

      if (findAnimateClass) {
        for (let i = 0; i < Object.keys(o.currentElements).length; i++) {
          const c = Object.keys(o.currentElements)[i];
          if (
            o.currentElements[c].el &&
            o.currentElements[c].el.classList.contains('toline')
          ) {
            const toLineMask =
              o.currentElements[c].el.querySelector('.toline-mask');
            if (toLineMask) {
              const elTop = o.currentElements[c].top,
                elOffsetHeight = o.currentElements[c].el.offsetHeight,
                h = scrollY + windowInnerHeight - elTop - elOffsetHeight,
                w = Math.round(
                  (4 * h) / ((windowInnerHeight + elOffsetHeight) / 10),
                ),
                g = Math.min(200, Math.max(0, w));
              toLineMask.style.width = `${g}%`;
            }
          }
        }
      }
    });

    // setTimeout(() => {
    //   if (!mobileMenu) {
    //     scroll.update();
    //   }
    // }, 1000);
  }, []);

  return (
    <>
      <div data-scroll-container ref={containerRef}>
        <Header fixed={true} main={true} />
        <div data-scroll-section>
          {/* main section */}
          <div className="relative" id="home">
            <div className="absolute w-full h-full z-[2] lg:bg-[url(assets/images/section-bg-1.png)] lg:bg-center" />
            <div className="absolute left-0 top-0 w-full h-full z-[3] bg-gradient-to-b from-transparent to-[#0c111c]/70" />
            <div className="relative z-10">
              <Layout>
                <div className="h-screen lg:pt-28">
                  <div className="flex flex-row h-full justify-center relative">
                    <div className="space-y-6 w-full lg:w-[650px] lg:justify-center flex-col flex lg:flex-none pb-24 pt-20">
                      <div className="lg:hidden mt-20 relative z-10 -mb-20">
                        <div className="relative">
                          <img src="/assets/images/landing-image.png" alt="" />
                          <div className="absolute z-20 h-[200px] w-full left-0 bottom-0 bg-gradient-to-b from-transparent to-[#0c111c]"></div>
                        </div>
                      </div>

                      {/* landing text */}
                      <div className="text-gray-50 font-garamond font-bold text-center text-7xl lg:text-4xl lg:text-left z-20 lg:mt-0">
                        <div
                          data-scroll
                          data-scroll-speed="1"
                          data-scroll-position="top"
                        >
                          {t('home.hero.title.0')}
                        </div>
                        <div
                          data-scroll
                          data-scroll-speed=".5"
                          data-scroll-position="top"
                        >
                          {t('home.hero.title.1')}
                        </div>
                      </div>

                      {/* landing text */}
                      <div className="lg:text-2xl font-normal text-center lg:text-left">
                        <span
                          data-scroll
                          data-scroll-offset="10%"
                          data-scroll-class="toline"
                          className="toline toline-text text-gray-300"
                        >
                          {t('home.hero.description.0')}
                          <span className="toline-mask text-[#D60101]">
                            {t('home.hero.description.0')}
                          </span>
                        </span>
                        <span
                          data-scroll
                          data-scroll-offset="10%"
                          data-scroll-class="toline"
                          className="toline toline-text text-gray-300"
                        >
                          {t('home.hero.description.1')}
                          <span className="toline-mask text-[#D60101]">
                            {t('home.hero.description.1')}
                          </span>
                        </span>
                      </div>

                      {/* landing image */}
                      <div
                        className="flex justify-center lg:block relative z-30"
                        data-scroll
                        data-scroll-speed=".2"
                        data-scroll-position="top"
                      >
                        <div className="flex space-x-4 relative">
                          <div>
                            <a
                              target="_blank"
                              href="https://apps.apple.com/us/app/patrol-local-safety-alerts/id1658765150"
                            >
                              <Image
                                src={`/assets/images/app-store.png`}
                                className="w-[135px] h-auto block"
                              />
                            </a>
                          </div>
                          <div>
                            <a
                              target="_blank"
                              href="https://play.google.com/store/apps/details?id=app.vyral.patrol"
                            >
                              <Image
                                src={`/assets/images/google-store.png`}
                                className="w-[135px] h-auto block"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hidden lg:flex justify-center items-center">
                      <div>
                        <img src="/assets/images/landing-image.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </Layout>
            </div>
          </div>
          {/* app screen section */}
          <div
            id="app-screen"
            data-scroll
            data-scroll-repeat="true"
            data-scroll-call="APP_SCREEN"
            className="flex h-full flex-col justify-center items-center py-28"
          >
            <Layout>
              {/* wrap */}
              <div className="flex flex-col lg:flex-row gap-y-16 lg:gap-8 justify-center items-start">
                {/* left */}
                <div className="flex flex-col items-center justify-between about-item w-full lg:w-[400px] lg:h-[445px]">
                  <div className="relative">
                    <div className="relative z-10">
                      <img
                        src={`/assets/images/m/left-base-1.png`}
                        className="w-52 m-image"
                      />
                    </div>
                    <div className="absolute z-20 h-[100px] w-full left-0 bottom-0 bg-gradient-to-b from-transparent to-[#0c111c]"></div>
                  </div>
                  <div className="text-center pt-6 space-y-2">
                    <h2 className="text-[#D60101] font-garamond font-semibold text-3xl">
                      {t('home.appScreens.0.title')}
                    </h2>
                    <div
                      className="text-gray-50 text-xl font-medium"
                      dangerouslySetInnerHTML={{
                        __html: t('home.appScreens.0.text'),
                      }}
                    />
                  </div>
                </div>
                {/* middle */}
                <div className="flex flex-col items-center justify-between about-item w-full lg:w-[400px] lg:h-[445px]">
                  <div className="relative">
                    <div className="relative z-10">
                      <img
                        src={`/assets/images/m/front-base-1.png`}
                        className="w-56 m-image"
                      />
                    </div>
                    <div className="absolute z-20 h-[100px] w-full left-0 bottom-0 bg-gradient-to-b from-transparent to-[#0c111c]"></div>
                  </div>
                  <div className="text-center pt-6 space-y-2">
                    <h2 className="text-[#D60101] font-garamond font-semibold text-3xl">
                      {t('home.appScreens.1.title')}
                    </h2>
                    <div
                      className="text-gray-50 text-xl font-medium"
                      dangerouslySetInnerHTML={{
                        __html: t('home.appScreens.1.text'),
                      }}
                    />
                  </div>
                </div>
                {/* right */}
                <div className="flex flex-col items-center justify-between about-item w-full lg:w-[400px] lg:h-[445px]">
                  <div className="relative">
                    <div className="relative z-10">
                      <img
                        src={`/assets/images/m/right-base-1.png`}
                        className="w-52 m-image"
                      />
                    </div>
                    <div className="absolute z-20 h-[100px] w-full left-0 bottom-0 bg-gradient-to-b from-transparent to-[#0c111c]"></div>
                  </div>
                  <div className="text-center pt-6 space-y-2">
                    <h2 className="text-[#D60101] font-garamond font-semibold text-3xl">
                      {t('home.appScreens.2.title')}
                      <br></br>
                    </h2>
                    <div
                      className="text-gray-50 text-xl font-medium"
                      dangerouslySetInnerHTML={{
                        __html: t('home.appScreens.2.text'),
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* wrap */}
            </Layout>
          </div>
          <div
            id="b2c"
            data-scroll
            data-scroll-repeat="false"
            data-scroll-call="B2C"
            className="flex h-full flex-col justify-center items-center py-20 bg-[url(assets/images/section-bg-1.png)]"
          >
            <Layout>
              <div className="lg:flex lg:justify-center">
                <div className="section-title pt-0 pb-8">
                  <h2>{t('home.b2c.title')}</h2>
                </div>
              </div>
            </Layout>
            <div className="flex w-full ">
              <Layout>
                <div className="lg:flex lg:justify-center">
                  <div className="flex gap-10 lg:gap-20 lg:justify-center items-center flex-col lg:flex-row">
                    <div className="w-[180px]">
                      <img
                        src="/assets/images/iPhone-1407.png"
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                        }}
                        alt=""
                      />
                    </div>
                    <div className="lg:w-[700px] mx-auto mini flex flex-col text-gray-300 text-xl space-y-8 text-center lg:text-left">
                      <p className="p-0 m-0">{t('home.b2c.text.0')}</p>
                      <p className="p-0 m-0">{t('home.b2c.text.1')}</p>
                      <p className="p-0 m-0">{t('home.b2c.text.2')}</p>
                      <p className="p-0 m-0">{t('home.b2c.text.3')}</p>
                      <p className="p-0 m-0">{t('home.b2c.text.4')}</p>
                    </div>
                  </div>
                </div>
              </Layout>
            </div>
          </div>
          <div
            id="b2b"
            data-scroll
            data-scroll-repeat="false"
            data-scroll-call="B2B"
            className="flex h-full flex-col justify-center items-center pt-28 pb-28"
          >
            <Layout>
              <div className="lg:flex lg:justify-center">
                <div>
                  <div className="section-title pt-0 pb-8">
                    <h2>{t('home.b2b.title')}</h2>
                  </div>
                  <div
                    className="lg:w-[700px] mx-auto mini text-gray-300 text-xl space-y-6 text-center"
                    dangerouslySetInnerHTML={{
                      __html: t('home.b2b.text'),
                    }}
                  />
                </div>
              </div>
            </Layout>
          </div>
          <ParrallaxTiles id="parallax-1" />
          {/* advisor section */}

          <div data-scroll data-scroll-call="FOOTER" id="footer">
            <SubscribeEmail />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
