import React, { FC, useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './i18n/config';
import { ComingSoon, CookieBanner } from './components';
import {
  ContactUsPage,
  CookiePolicy,
  Error404,
  Home,
  PrivacyPolicy,
  TermsAndConditions,
  MobileLinking,
  DownloadNow,
} from './containers';
import { useGlobal } from './context/global.context';

interface WrapperPorps {
  children: React.ReactNode;
}

const Wrapper: FC<WrapperPorps> = ({ children }: WrapperPorps) => {
  const { mobileMenuClose } = useGlobal();
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);

    mobileMenuClose();
  }, [location]);

  return <>{children}</>;
};

const App: FC = () => {
  return (
    <BrowserRouter>
      <Wrapper>
        <ComingSoon />
        <CookieBanner />
        <Routes>
          <Route index element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="contact-us" element={<ContactUsPage />} />
          <Route path="/mobile/:type/:id" element={<MobileLinking />} />
          <Route path="download-now" element={<DownloadNow />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
};

export default App;
