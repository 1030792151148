import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialMediaIcons } from '../Button';
import { Image } from '../Image';
import { Layout } from '../Layout';

export const Footer: FC = () => {
  const { t, i18n } = useTranslation();
  const [langMenuOpen, setLangMenuOpen] = useState<boolean>(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lang', lng);
    setLangMenuOpen(false);
  };

  return (
    <>
      {/* footer */}
      <div className="bg-primary p-6 text-white">
        <Layout>
          <div className="justify-between items-center flex flex-col lg:grid lg:grid-cols-3 space-y-6">
            <div className="space-y-2 text-center lg:text-left">
              <div className="flex justify-center lg:block">
                <Image
                  src={`/assets/images/patrol-footer-logo.png`}
                  className="w-[160px] h-auto block"
                />
              </div>
              <div>
                <SocialMediaIcons
                  color="#FFFFFF"
                  className="footer-bottom-social"
                />
              </div>
              <div className="text-xs">
                <p
                  dangerouslySetInnerHTML={{ __html: t('footer.copy.title') }}
                />
                <p>{t('footer.copy.text')}</p>
              </div>
            </div>
            <div>
              <div className="text-center text-sm">
                <div className="font-bold">{t('footer.address.0')}</div>
                <div>{t('footer.address.1')}</div>
                <div>{t('footer.address.2')}</div>
              </div>
              <div className="flex space-x-4 mt-4 justify-center">
                <div>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/patrol-local-safety-alerts/id1658765150"
                  >
                    <Image
                      src={`/assets/images/app-store.png`}
                      className="w-[135px] h-auto block"
                    />
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=app.vyral.patrol"
                  >
                    <Image
                      src={`/assets/images/google-play.png`}
                      className="w-[135px] h-auto block"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex flex-col">
                <div className="flex flex-col space-y-4 text-center lg:text-left w-auto">
                  <a
                    href="/terms-and-conditions"
                    className="block font-bold text-sm "
                  >
                    {t('footer.menu.termsAndConditions')}
                  </a>
                  <a href="/privacy-policy" className="block font-bold text-sm">
                    {t('footer.menu.privacyPolicy')}
                  </a>
                  <a href="/cookie-policy" className="block font-bold text-sm">
                    {t('footer.menu.cookiePolicy')}
                  </a>
                  {/* href="/contact-us"  TODO*/}
                  <a href="/contact-us" className="block font-bold text-sm">
                    {t('footer.menu.contactUs')}
                  </a>
                </div>
                {/* select lang */}
                <div className="select-lang-wrap flex justify-center lg:justify-start">
                  {langMenuOpen && (
                    <div className="lang-list">
                      <div
                        onClick={() => {
                          changeLanguage('en');
                        }}
                      >
                        English
                      </div>
                      <div
                        onClick={() => {
                          changeLanguage('tr');
                        }}
                      >
                        Türkçe
                      </div>
                    </div>
                  )}
                  <div
                    className="current-lang uppercase"
                    onClick={() => {
                      setLangMenuOpen(!langMenuOpen);
                    }}
                  >
                    {i18n.language}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
};
