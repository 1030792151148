import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, ButtonLoading, SocialMediaIcons } from '../Button';
import { Input } from '../Input';

interface Inputs {
  email: string;
}

export const SubscribeEmail: FC = () => {
  const { t } = useTranslation();

  const [subscribeEmailSuccess, setSubscribeEmailSuccess] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSubscribeEmailSuccess(true);
    }, 1200);
  };

  return (
    <>
      <div className="lg:w-[410px] mx-auto mini pt-10">
        <h2
          className="text-white font-garamond font-medium text-2xl text-center"
          dangerouslySetInnerHTML={{ __html: t('subscribeEmail.title') }}
        />
        <div className="flex justify-center">
          <SocialMediaIcons className="social-large footer-bottom-social pt-4" />
        </div>
      </div>

      {/* subscribe form */}
      <div className="p-10 lg:px-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex md:flex-row md:justify-center md:items-center lg:flex lg:flex-row lg:justify-center lg:items-end lg:gap-6 text">
            <div className="mb-4 lg:mb-0 relative">
              <label className="block text-gray-50 mb-1">
                {t('subscribeEmail.email')}
              </label>
              <div className="w-full">
                <Input
                  className={`lg:w-[320px] ${
                    errors.email ? 'border-red-500' : ''
                  } `}
                  {...register('email', {
                    required: t('subscribeEmail.error.required'),
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t('subscribeEmail.error.email'),
                    },
                  })}
                  placeholder={t('subscribeEmail.inputPlaceholder') ?? ''}
                />
              </div>
              {errors.email && (
                <div className="text-red-500 text-sm mt-1 absolute -bottom-7 left-0">
                  {errors.email?.message}
                </div>
              )}
            </div>
            <div className="flex justify-center items-center lg:block md:mt-3">
              <Button
                className="w-full uppercase"
                size={'large'}
                disabled={loading}
              >
                {loading && <ButtonLoading />}
                {t('subscribeEmail.subscribe')}
              </Button>
            </div>
          </div>
        </form>
        {subscribeEmailSuccess && (
          <div className="relative flex justify-center items-center">
            <div className="absolute top-1 justify-center items-center">
              <div className="text-center text-base mt-6">
                <div className="text-gray-50 font-bold">
                  {t('subscribeEmail.successMessage.title')}
                </div>
                <div className="text-gray-300 font-medium">
                  {t('subscribeEmail.successMessage.text')}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
