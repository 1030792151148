import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Layout } from '../../components';

export const Error404: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Layout>
        <div className="p-16 text-center">
          <h1 className="text-3xl font-bold font-garamond text-gray-50 uppercase text-center">
            404
          </h1>
          <h2 className="text-gray-50 mt-4">{t('404Page.description')}</h2>
          <div className="mt-12">
            <Link
              to="/"
              className="bg-primary rounded-full text-sm px-3 py-1.5 inline-block"
            >
              {t('404Page.btnText')}
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};
