import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter, FaThreads } from 'react-icons/fa6';
import { RiInstagramFill } from 'react-icons/ri';

import btnLoading from '../../assets/images/btn-loading.gif';

type ButtonSizes = 'normal' | 'large' | 'small' | 'small-square';

interface BaseButtonProps {
  [key: string]: any;
  size?: ButtonSizes | null;
  disabled?: boolean;
}

type ButtonAsButton = BaseButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonAsLink = BaseButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = ButtonAsButton | ButtonAsLink;

const BaseButton = ({ href, forwardedRef, ...rest }: any) => {
  if (href) {
    return <a ref={forwardedRef} href={href} {...rest} />;
  }

  return <button ref={forwardedRef} {...rest} />;
};

const baseClasses: any[string] = [
  'flex',
  'flex-none',
  'items-center',
  'justify-center',
  'cursor-pointer',
  'leading-tight',
  'transition-all',
  'hover:bg-[#A60101]',
  'font-bold',
];

const getSize = (size: ButtonSizes): string => {
  switch (size) {
    case 'large':
      return 'px-4 h-10 text-sm';
    case 'small':
      return 'px-2.5 py-1.5 text-xs';
    case 'small-square':
      return 'p-2 text-sm';
    case 'normal':
    default: {
      return 'h-10 px-3 text-xs';
    }
  }
};

const getOpacity = (disabled: boolean): string => {
  return disabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100';
};

const getRadius = (size: ButtonSizes): string => {
  switch (size) {
    case 'large':
      return 'rounded-full';
    case 'small':
      return 'rounded';
    case 'normal':
    default:
      return 'rounded-2xl';
  }
};

const mergeClasses = (classes: string[]): string => {
  return [...baseClasses, ...classes].join(' ');
};

const composer = {
  getSize,
  getOpacity,
  getRadius,
};

export const ButtonLoading: FC = () => {
  return (
    <>
      <img src={btnLoading} style={{ width: 18, height: 18 }} alt="" />
    </>
  );
};

export const Button: FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref) => {
    const size = composer.getSize(props.size || 'normal');
    const opacity = composer.getOpacity(props.disabled || false);
    const radius = composer.getRadius(props.size || 'normal');
    const classes = mergeClasses([
      'bg-primary text-white',
      size,
      opacity,
      radius,
    ]);

    return (
      <BaseButton
        forwardedRef={ref}
        {...props}
        className={`${classes} ${props.className}`}
      />
    );
  },
);

export const OutlineButton = React.forwardRef((props: ButtonProps, ref) => {
  const size = composer.getSize(props.size || 'normal');
  const opacity = composer.getOpacity(props.disabled || false);
  const radius = composer.getRadius(props.size || 'normal');
  const classes = mergeClasses([
    'border',
    'border-primary',
    'hover:text-white',
    size,
    opacity,
    radius,
  ]);

  return (
    <BaseButton
      forwardedRef={ref}
      {...props}
      className={`${classes} ${props.className}`}
    />
  );
});

export const LinkOrScroll = ({ href, main, ...rest }: any) => {
  if (main) {
    return <a href={href} data-scroll-to {...rest} />;
  }

  return <Link to={`/${href}`} {...rest} />;
};

export const SocialMediaIcons = ({ size, color, ...props }: any) => {
  return (
    <div className={`social-icons ${props.className}`}>
      <div className="social-line">
        {/*href="https://www.instagram.com/usefanluv/" TODO*/}
        <a
          href="https://www.instagram.com/usepatrolapp/"
          className="block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RiInstagramFill size={size || 16} color={color || 'D1D5DB'} />
        </a>
        <a
          href="https://twitter.com/AppPatrol"
          className="block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaXTwitter size={size || 16} color={color || 'D1D5DB'} />
        </a>
        <a
          href="https://www.threads.net/"
          className="block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaThreads size={size || 16} color={color || 'D1D5DB'} />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100089227734526"
          className="block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook size={size || 16} color={color || 'D1D5DB'} />
        </a>
        <a
          href="https://www.tiktok.com/@usepatrolapp"
          className="block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTiktok size={size || 16} color={color || 'D1D5DB'} />
        </a>
        <a
          href="https://www.youtube.com/watch?v=ncpBEkLDL2Y&ab_channel=Usepatrolapp"
          className="block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube size={size || 16} color={color || 'D1D5DB'} />
        </a>
      </div>
    </div>
  );
};
