import React from 'react';

interface ImageProps {
  src: string;
}

type ImageType = ImageProps & React.ImgHTMLAttributes<HTMLImageElement>;

export const Image = React.forwardRef(
  ({ src, ...rest }: ImageType, ref: any) => {
    return <img src={src} {...rest} ref={ref} />;
  },
);
