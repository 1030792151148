import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, LinkOrScroll, SocialMediaIcons } from '../Button';

export const MobileNavigation: FC<any> = ({ main }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <nav className="mobile-nav justify-between flex flex-col md:text-center overflow-y-auto lg:hidden">
        <ul>
          <li className="">
            <div className="mobile-nav-dropdown ">
              <ul>
                <li>
                  <LinkOrScroll
                    className="nav-item-link"
                    href="#b2c"
                    main={main || false}
                  >
                    {t('menu.b2c')}
                  </LinkOrScroll>
                </li>
                <li>
                  <LinkOrScroll
                    className="nav-item-link"
                    href="#b2b"
                    main={main || false}
                  >
                    {t('menu.b2b')}
                  </LinkOrScroll>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div className="md:w-[50%] md:mx-auto">
          <div className="px-4">
            <Button href="/contact-us" size="large">
              {t('header.contactUs')}
            </Button>
          </div>

          <div className="my-6">
            <div className="flex justify-center items-center mb-8">
              <SocialMediaIcons className="mobile-navigation" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
