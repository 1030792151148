import React, { FC } from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <>
      <div className={`container mx-auto px-4 lg:px-0`}>{children}</div>
    </>
  );
};

interface TextWrapProps {
  children: React.ReactNode;
  title: string;
}

export const TextWrap: FC<TextWrapProps> = ({
  children,
  title,
}: TextWrapProps) => {
  return (
    <>
      <div className="lg:p-16 min-h/5">
        <h1 className="text-xl text-center lg:text-left lg:text-3xl font-bold font-garamond text-gray-50 uppercase">
          {title}
        </h1>
        <div className="text-wrap mt-4">{children}</div>
      </div>
    </>
  );
};
