import { FC } from 'react';

interface TileRowType {
  speed: number;
  images: Array<string>;
  id: string;
}

const TileRow: FC<TileRowType> = ({ speed, images, id }: TileRowType) => {
  return (
    <div
      className="tiles-row"
      data-scroll
      data-scroll-speed={speed}
      data-scroll-target={`#${id}`}
      data-scroll-direction="horizontal"
    >
      {images.map((image: string, key: number) => {
        return (
          <div
            key={key}
            className="tile-image"
            style={{
              backgroundImage: `url('/${image}')`,
            }}
          />
        );
      })}
    </div>
  );
};

interface ParrallaxTilesType {
  id: string;
}

export const ParrallaxTiles: FC<ParrallaxTilesType> = ({
  id,
}: ParrallaxTilesType) => {
  const rows = [
    {
      speed: 1,
      images: [
        'assets/images/parallax/1.jpg',
        'assets/images/parallax/2.jpg',
        'assets/images/parallax/3.jpg',
        'assets/images/parallax/4.jpg',
        'assets/images/parallax/5.jpg',
        'assets/images/parallax/6.jpg',
      ],
    },
    {
      speed: -1,
      images: [
        'assets/images/parallax/7.jpg',
        'assets/images/parallax/8.jpg',
        'assets/images/parallax/9.jpg',
        'assets/images/parallax/10.jpg',
        'assets/images/parallax/11.jpg',
        'assets/images/parallax/11.jpg',
      ],
    },
    {
      speed: 1,
      images: [
        'assets/images/parallax/1.jpg',
        'assets/images/parallax/2.jpg',
        'assets/images/parallax/3.jpg',
        'assets/images/parallax/4.jpg',
        'assets/images/parallax/5.jpg',
        'assets/images/parallax/6.jpg',
      ],
    },
    {
      speed: -1,
      images: [
        'assets/images/parallax/7.jpg',
        'assets/images/parallax/8.jpg',
        'assets/images/parallax/9.jpg',
        'assets/images/parallax/10.jpg',
        'assets/images/parallax/11.jpg',
        'assets/images/parallax/1.jpg',
      ],
    },
    {
      speed: 1,
      images: [
        'assets/images/parallax/2.jpg',
        'assets/images/parallax/3.jpg',
        'assets/images/parallax/4.jpg',
        'assets/images/parallax/5.jpg',
        'assets/images/parallax/6.jpg',
        'assets/images/parallax/7.jpg',
      ],
    },
    {
      speed: -1,
      images: [
        'assets/images/parallax/1.jpg',
        'assets/images/parallax/2.jpg',
        'assets/images/parallax/3.jpg',
        'assets/images/parallax/4.jpg',
        'assets/images/parallax/5.jpg',
        'assets/images/parallax/6.jpg',
      ],
    },
    {
      speed: 1,
      images: [
        'assets/images/parallax/6.jpg',
        'assets/images/parallax/5.jpg',
        'assets/images/parallax/4.jpg',
        'assets/images/parallax/3.jpg',
        'assets/images/parallax/2.jpg',
        'assets/images/parallax/1.jpg',
      ],
    },
    {
      speed: -1,
      images: [
        'assets/images/parallax/7.jpg',
        'assets/images/parallax/8.jpg',
        'assets/images/parallax/9.jpg',
        'assets/images/parallax/10.jpg',
        'assets/images/parallax/11.jpg',
        'assets/images/parallax/1.jpg',
      ],
    },
    {
      speed: 1,
      images: [
        'assets/images/parallax/2.jpg',
        'assets/images/parallax/3.jpg',
        'assets/images/parallax/4.jpg',
        'assets/images/parallax/1.jpg',
        'assets/images/parallax/2.jpg',
        'assets/images/parallax/3.jpg',
      ],
    },
  ];

  return (
    <>
      <div className="overflow-hidden h-[400px]" id={id}>
        <div className="tiles-wrapper relative gap-8 flex flex-col">
          {rows.map((item: any, key: number) => {
            return (
              <TileRow
                key={key}
                id={id}
                speed={item.speed}
                images={item.images}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
