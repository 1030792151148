import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from '../../context/global.context';
import { Image } from '../Image';
import { Layout } from '../Layout';
import { MobileNavigation } from './MobileNavigation';
import { Navigation } from './Navigation';

const HeaderLogo = () => {
  return (
    <Image className="w-[160px]" src={`/assets/images/patrol-logo.png?v=1`} />
  );
};

type HeaderProps = {
  main?: boolean;
  size?: 'large' | 'small';
  fixed?: boolean;
};

export const Header: FC<HeaderProps> = ({
  main = false,
  fixed = false,
}: HeaderProps) => {
  const { mobileMenuHandler, mobileMenu } = useGlobal();

  const menuButton = () => {
    mobileMenuHandler();
  };

  return (
    <>
      {mobileMenu && <MobileNavigation main={main} />}
      <div
        className={
          fixed ? `fixed top-0 z-30 w-full transition` : `relative z-30 `
        }
      >
        {/* <HeaderTop /> */}
        <div>
          <div className="relative backdrop-blur z-20">
            <Layout>
              <div className="flex justify-between items-center">
                <div className="flex-none">
                  {main ? (
                    <a href="#home" data-scroll-to>
                      <HeaderLogo />
                    </a>
                  ) : (
                    <Link to="/">
                      <HeaderLogo />
                    </Link>
                  )}
                </div>
                <div className="hidden lg:flex justify-end items-center h-20 w-full">
                  <Navigation main={main} />
                </div>
                <div className="lg:hidden h-20 flex items-center">
                  <button
                    className={`nav-menu-icon ${mobileMenu ? 'open' : ''}`}
                    onClick={() => {
                      menuButton();
                    }}
                  >
                    <i></i>
                    <i></i>
                    <i></i>
                  </button>
                </div>
              </div>
            </Layout>
          </div>
        </div>
      </div>
    </>
  );
};
