import { FC, useEffect, useRef, useState } from 'react';
import { Image } from '../Image';
import { useTranslation } from 'react-i18next';
type ConfirmModalProps = {
  show?: boolean;
  location?: string | null;
};

export const ConfirmModal: FC<ConfirmModalProps> = ({
  show = false,
  location = null,
}: ConfirmModalProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (titleRef) {
      setTimeout(() => {
        titleRef?.current?.classList.add('active');
      }, 250);
    }
    setShowModal(show);
  }, [show]);

  return (
    <>
      {showModal && (
        <div
          onClick={() => {
            if (!location) {
              setShowModal(!showModal);
            } else {
              window.location.href = location;
            }
          }}
          className="bg-[#0C111C]/50 backdrop-blur-sm fixed left-0 top-0 w-full h-full z-50"
        >
          <div className="flex w-full h-full justify-center items-center">
            <div
              className={`w-[440px] border-2 rounded-[32px] overflow-hidden border-[#D60101] bg-[#D60101]`}
            >
              <div className="p-8">
                <div className="mb-8 flex justify-center">
                  <Image src={`/assets/images/coming-soon-logo.png`} />
                </div>
                <div className="space-y-4 text-center">
                  <div className="comingsoon-title" ref={titleRef}>
                    {t('confirmModal.messageSent')}
                    <span>{t('confirmModal.messageSent')}</span>
                  </div>
                </div>
              </div>
              <div className="bg-[#0C111C] text-center py-4">
                <div
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                  className="font-bold text-base text-gray-50 cursor-pointer transition-all hover:opacity-50"
                >
                  {t('confirmModal.keepBrowsing')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
