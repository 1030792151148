export default [
  {
    name: 'Ertunc Ciris',
    title: 'Founder & CEO',
    image: '/assets/images/teams/ertunc-ciris.png',
    linkedin: 'https://www.linkedin.com/in/ertuncciris/',
  },
  {
    name: 'Hakan Vural',
    title: 'Sr. Blockchain Developer',
    image: '/assets/images/teams/hakan-vural.png',
    linkedin: 'https://www.linkedin.com/in/hakanvural/',
  },
  {
    name: 'Abdullah Ercan',
    title: 'Sr. Backend Developer',
    image: '/assets/images/teams/abdullah-ercan.png',
    linkedin: 'https://www.linkedin.com/in/abdullahercan/',
  },
  {
    name: 'Burak Erenel',
    title: 'Sr. Backend Developer',
    image: '/assets/images/teams/burak-erenel.png',
    linkedin: 'https://www.linkedin.com/in/burak-erenel-a5a808167/',
  },
  {
    name: 'Jay Tang',
    title: 'Advisor',
    image: '/assets/images/teams/jay-tang.png',
    linkedin: '',
  },
  {
    name: 'Esha Sahai',
    title: 'Advisor',
    image: '/assets/images/teams/esha-sahai.png',
    linkedin: '',
  },
  {
    name: 'Ertunc Ciris',
    title: 'Founder & CEO',
    image: '/assets/images/teams/ertunc-ciris.png',
    linkedin: 'https://www.linkedin.com/in/ertuncciris/',
  },
  {
    name: 'Hakan Vural',
    title: 'Sr. Blockchain Developer',
    image: '/assets/images/teams/hakan-vural.png',
    linkedin: 'https://www.linkedin.com/in/hakanvural/',
  },
  {
    name: 'Abdullah Ercan',
    title: 'Sr. Backend Developer',
    image: '/assets/images/teams/abdullah-ercan.png',
    linkedin: 'https://www.linkedin.com/in/abdullahercan/',
  },
  {
    name: 'Burak Erenel',
    title: 'Sr. Backend Developer',
    image: '/assets/images/teams/burak-erenel.png',
    linkedin: 'https://www.linkedin.com/in/burak-erenel-a5a808167/',
  },
  {
    name: 'Jay Tang',
    title: 'Advisor',
    image: '/assets/images/teams/jay-tang.png',
    linkedin: '',
  },
  {
    name: 'Esha Sahai',
    title: 'Advisor',
    image: '/assets/images/teams/esha-sahai.png',
    linkedin: '',
  },
];
