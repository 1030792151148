import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, Header, Layout, TextWrap } from '../../components';

export const CookiePolicy: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Layout>
        <TextWrap title={t('cookiePolicy.title')}>
          <div dangerouslySetInnerHTML={{ __html: t('cookiePolicy.text') }} />
        </TextWrap>
      </Layout>
      <Footer />
    </>
  );
};
