import React, { FC } from 'react';
import Select, { StylesConfig } from 'react-select';

const styles: Array<string> = [
  'bg-white',
  'text-gray-500',
  'w-full',
  'text-base',
  'font-normal',
  'rounded-xl',
  'outline-none',
  'border-2',
  'border-gray-300',
  'focus:border-[#D60101]',
];

interface InputProps {
  className?: string;
  ref?: any;
}

type InputType = InputProps & React.InputHTMLAttributes<HTMLInputElement>;

export const Input: FC<InputType> = React.forwardRef(
  ({ className, ...rest }: InputType, ref: any) => {
    return (
      <input
        className={`${styles.join(' ')} h-10 px-3 ${className}`}
        {...rest}
        ref={ref}
      />
    );
  },
);

type TextAreaType = InputProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = React.forwardRef(
  ({ className, ...rest }: TextAreaType, ref: any) => {
    return (
      <textarea
        ref={ref}
        className={`${styles.join(' ')} p-3 resize-none ${className}`}
        {...rest}
      ></textarea>
    );
  },
);

interface LabelInputProps {
  label?: string;
  error?: string;
  labelClass?: string;
  inputClass?: string;
  required?: boolean;
  type?: 'input' | 'textarea';
}

type LabelInputType = LabelInputProps;

export const LabelInput: FC<LabelInputType> = React.forwardRef(
  (
    {
      type = 'input',
      inputClass = '',
      labelClass = '',
      error = '',
      label = '',
      required = false,
      ...props
    }: LabelInputType,
    ref,
  ) => {
    return (
      <div className={`${labelClass} ${error ? `label-error` : ``}`}>
        {label && (
          <label className="block text-gray-200 text-sm font-bold mb-1">
            {label} {required ? <span className="text-red-500">*</span> : ''}
          </label>
        )}
        <div>
          {type === 'input' ? (
            <Input
              className={`${inputClass} ${error ? `border-red-500` : ``}`}
              ref={ref}
              {...props}
            />
          ) : (
            <TextArea
              className={`${inputClass} ${error ? `border-red-500` : ``}`}
              ref={ref}
              {...props}
            />
          )}
        </div>
        {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
      </div>
    );
  },
);

interface SelectProps {
  className?: string;
  children: any;
}

type SelectType = SelectProps & React.SelectHTMLAttributes<HTMLSelectElement>;

export const SelectBox = React.forwardRef(
  ({ className, ...props }: SelectType, ref: any) => {
    return (
      <select
        ref={ref}
        className={`${styles.join(' ')} h-10 px-3 ${className}`}
        {...props}
      />
    );
  },
);

export const SearchableSelect = React.forwardRef(
  ({ error, ...props }: any, ref: any) => {
    const flag = (flag: string) => ({
      ':before': {
        content: '"' + flag + ' "',
        fontSize: 12,
        display: 'inline-block',
        marginRight: 8,
      },
    });

    const colourStyles: StylesConfig = {
      control: (styles) => ({
        ...styles,
        borderRadius: '.75rem',
        width: 140,
        height: '2.5rem',
        boxShadow: 'none',
        borderWidth: 2,
        borderColor: error ? 'red' : 'none',
        ':hover': {
          borderColor: '#D60101',
          boxShadow: 'none',
        },
      }),
      option: (styles, { data }: any) => {
        return {
          ...styles,
          ...flag(data.flag as string),
        };
      },
      input: (styles) => {
        return {
          ...styles,
        };
      },
      placeholder: (styles) => {
        return {
          ...styles,
        };
      },
      singleValue: (styles, { data }: any) => {
        return {
          ...styles,
          ...flag(data.flag as string),
        };
      },
    };

    return (
      <Select styles={colourStyles} isSearchable={true} {...props} ref={ref} />
    );
  },
);
