import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactFormPaylaod, ContactFormValues } from '../../@types';
import {
  Button,
  Footer,
  Header,
  LabelInput,
  Layout,
  ButtonLoading,
} from '../../components';
import { ConfirmModal } from '../../components/ConfirmModal';
import { contactForm } from '../../services';

export const ContactUsPage: FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContactFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      subject: '',
      message: '',
    },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<ContactFormValues> = (data) => {
    setLoading(true);
    setFormError('');

    const { firstName, lastName, email, subject, message } = data;

    const payload: ContactFormPaylaod = {
      firstName,
      lastName,
      email,
      subject,
      message,
      ticketBase: 'web',
    };

    contactForm(payload)
      .then(() => {
        setShowModal(true);
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          setFormError(t(`errors.message.cannot.be.sent`));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ConfirmModal show={showModal} location="/" />
      <Header />
      <div className="relative">
        <div className="pt-10 pb-6 bg-gradient-to-b from-transparent to-[#343C4A]">
          <div className="flex justify-center">
            <img
              src="/assets/images/patrol-top.png"
              className="w-56 h-auto block mb-6"
            />
          </div>
          <h1 className="text-3xl text-center text-[#D60101] font-normal font-garamond">
            {t('contactUs.title')}
          </h1>
        </div>
        <Layout>
          <div className="py-16 lg:p-16">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="space-y-6 lg:w-[400px] mx-auto">
                <LabelInput
                  label={t('contactUs.form.firstName') ?? ''}
                  required={true}
                  error={errors.firstName?.message}
                  {...register('firstName', {
                    required: t('contactUs.form.error.required'),
                  })}
                />
                <LabelInput
                  label={t('contactUs.form.lastName') ?? ''}
                  required={true}
                  error={errors.lastName?.message}
                  {...register('lastName', {
                    required: t('contactUs.form.error.required'),
                  })}
                />
                <LabelInput
                  label={t('contactUs.form.email') ?? ''}
                  required={true}
                  error={errors.email?.message}
                  {...register('email', {
                    required: t('contactUs.form.error.required'),
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t('contactUs.form.error.email'),
                    },
                  })}
                />
                <LabelInput
                  label={t('contactUs.form.subject') ?? ''}
                  required={true}
                  error={errors.subject?.message}
                  {...register('subject', {
                    required: t('contactUs.form.error.required'),
                  })}
                />
                <LabelInput
                  label={t('contactUs.form.message') ?? ''}
                  required={true}
                  error={errors.message?.message}
                  {...register('message', {
                    required: t('contactUs.form.error.required'),
                  })}
                  type="textarea"
                />

                {formError && (
                  <div
                    className="mt-8 p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                    role="alert"
                  >
                    <strong>{t('errors.title')}</strong> {formError}
                  </div>
                )}

                <Button
                  className="w-full gap-2"
                  size="large"
                  disabled={loading}
                >
                  {t('contactUs.form.send')}
                  {loading && <ButtonLoading />}
                </Button>
              </div>
            </form>
          </div>
        </Layout>
      </div>
      <Footer />
    </>
  );
};
