import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, Header, Layout, TextWrap } from '../../components';

export const TermsAndConditions: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Layout>
        <TextWrap title={t('termsAndConditions.title')}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('termsAndConditions.contents', { joinArrays: '<br>' }),
            }}
          />
        </TextWrap>
      </Layout>
      <Footer />
    </>
  );
};
