import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, OutlineButton } from '../Button';

export const CookieBanner: FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    if (localStorage.getItem('cookieBanner')) {
      setShow(false);
    }
  }, []);

  const handleButton = (status: boolean) => {
    setShow(status);

    const bannerStatus = localStorage.getItem('cookieBanner');
    if (!bannerStatus) {
      localStorage.setItem('cookieBanner', 'true');
    }
  };

  return (
    <>
      {show && (
        <div className="fixed left-6 lg:left-auto right-6 bottom-6 lg:w-[520px] z-40">
          <div className="flex justify-between items-center flex-col lg:flex-row space-x-4 bg-gray-50 py-2 px-4 rounded-2xl">
            <div className="font-medium text-sm text-gray-900 text-center lg:text-left mb-2 lg:mb-0">
              {t('cookieBanner.text')}
              <a href="/cookie-policy" className="text-bold underline inline">
                {t('cookieBanner.learnMore')}
              </a>
            </div>
            <div className="flex justify-between space-x-4">
              <OutlineButton
                onClick={() => {
                  handleButton(false);
                }}
              >
                {t('cookieBanner.decline')}
              </OutlineButton>
              <Button
                onClick={() => {
                  handleButton(false);
                }}
              >
                {t('cookieBanner.accept')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
