import { createContext, useContext, ReactNode, useState } from 'react';
import { GlobalContextType } from '../@types';

import teams from './data/teams.js';

const globalContextDefaultValues: GlobalContextType = {
  comigSoon: false,
  mobileMenu: false,
  comigSoonHandler: () => {},
  mobileMenuHandler: () => {},
  mobileMenuClose: () => {},
  teams: [],
};

const GlobalContext = createContext<GlobalContextType>(
  globalContextDefaultValues,
);

export function useGlobal() {
  return useContext(GlobalContext);
}

type Props = {
  children: ReactNode;
};

export function GlobalProvider({ children }: Props) {
  const [comigSoon, setComigSoon] = useState<boolean>(false);
  const [mobileMenu, showMobileMenu] = useState<boolean>(false);

  const comigSoonHandler = () => {
    setComigSoon(!comigSoon);
  };

  const mobileMenuHandler = () => {
    showMobileMenu(!mobileMenu);

    if (mobileMenu) {
      document.body.style.overflow = 'scroll';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  const mobileMenuClose = () => {
    showMobileMenu(false);
    document.body.style.overflow = 'scroll';
  };

  const value = {
    comigSoon,
    mobileMenu,
    comigSoonHandler,
    mobileMenuHandler,
    mobileMenuClose,
    teams,
  };

  return (
    <>
      <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    </>
  );
}
