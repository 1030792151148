import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from '../../context/global.context';
import { Image } from '../Image';

export const ComingSoon: FC = () => {
  const { t } = useTranslation();
  const { comigSoon, comigSoonHandler } = useGlobal();
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef) {
      setTimeout(() => {
        titleRef?.current?.classList.add('active');
      }, 500);
    }
  }, [comigSoon]);

  return (
    <>
      {comigSoon && (
        <div
          onClick={() => {
            comigSoonHandler();
          }}
          className="bg-[#0C111C]/50 backdrop-blur-sm fixed left-0 top-0 w-full h-full z-50"
        >
          <div className="flex w-full h-full justify-center items-center">
            <div
              className={`w-[340px] border-2 rounded-[32px] overflow-hidden border-[#D60101] bg-[#D60101] bg-[url('/public/assets/images/comin-soon-bg.png')]`}
            >
              <div className="p-8">
                <div className="mb-8 flex justify-center">
                  <Image src={`/assets/images/coming-soon-logo.png`} />
                </div>
                <div className="space-y-4 text-center">
                  <div className="comingsoon-title" ref={titleRef}>
                    {t('comingSoon.title')}
                    <span>{t('comingSoon.title')}</span>
                  </div>
                  <div className="text-base text-white">
                    {t('comingSoon.description')}
                  </div>
                </div>
              </div>
              <div className="bg-[#0C111C] text-center py-4">
                <div
                  onClick={() => {
                    comigSoonHandler();
                  }}
                  className="font-bold text-base text-gray-50 cursor-pointer transition-all hover:opacity-50"
                >
                  {t('comingSoon.btn')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
