import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkOrScroll } from '../Button';

type NavigationProps = {
  main?: boolean;
};

export const Navigation: FC<NavigationProps> = ({ main }: NavigationProps) => {
  const { t } = useTranslation();
  const menuBgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const navDropdown = document.querySelectorAll('.d-menu');
    const menuBg = menuBgRef?.current;

    if (menuBg) {
      navDropdown.forEach((dMenu: any) => {
        dMenu?.addEventListener('mouseenter', () => {
          const d = dMenu.querySelector('.nav-dropdown');
          if (d) {
            menuBg.style.height = `${d.clientHeight + 16}px`;
            menuBg.style.opacity = '1';
          }
        });

        dMenu?.addEventListener('mouseleave', () => {
          menuBg.style.height = '0';
          menuBg.style.opacity = '0';
        });
      });
    }
  }, []);

  return (
    <>
      <nav className={`flex h-full md:ml-[70px]- lg:ml-[130px]- large`}>
        <ul className="flex flex-row space-x-8">
          <li className="nav-item relative d-menu">
            <LinkOrScroll
              className="nav-item-link"
              href="#b2c"
              main={main || false}
            >
              {t('menu.b2c')}
            </LinkOrScroll>
          </li>
          <li className="nav-item relative d-menu">
            <LinkOrScroll
              className="nav-item-link"
              href="#b2b"
              main={main || false}
            >
              {t('menu.b2b')}
            </LinkOrScroll>
          </li>
          <li className="nav-item">
            <a href={'/contact-us'} className="nav-item-link">
              {t('menu.contactUs')}
            </a>
          </li>
        </ul>
      </nav>
      <div
        ref={menuBgRef}
        className="absolute opacity-0 transition-all h-[0px] left-0 top-20 w-full z-10 border-y border-gray-500 bg-[#111827]/80 backdrop-blur"
      />
    </>
  );
};
